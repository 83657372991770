<template>
    <div>
        <b-row>
            <b-col sm="12">
                <div class="card card-default">
                    <div class="card-header">
                        <i class="fad fa-exchange mr-2"></i> Transfer
                    </div>
                    <div class="card-body">

                        <markdown-parser :source="$t('growing.view.plant_settings.transfer.description')"></markdown-parser>

                        <hr>

                        <div>
                            <div class="text-primary" v-if="stats.transfer_requirements.verification_level === 2">
                                <i class="fas fa-check mr-2"></i> {{ $t('growing.view.plant_settings.transfer.requirement.verification_level') }}
                            </div>
                            <div class="text-danger" v-else>
                                <i class="fas fa-times mr-2"></i> {{ $t('growing.view.plant_settings.transfer.requirement.verification_level') }} - <router-link tag="a" :to="{name: 'SettingsVerification'}">{{ $t('growing.view.plant_settings.transfer.link.verify_now') }}</router-link>
                            </div>
                        </div>
                        <hr>
                        <template v-if="stats.transfer_requirements.verification_level === 2">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" @change="acceptTransferTerms()" v-model="stats.transfer_enabled_at" :disabled="stats.transfer_enabled_at"
                                       name="agreements" id="agreement" class="custom-control-input">
                                <label class="custom-control-label" for="agreement">
                                    {{ $t('growing.view.plant_settings.transfer.input_terms') }}
                                </label>
                            </div>
                            <hr>
                        </template>
                        <div>
                            <span class="text-primary" v-show="stats.transfer_enabled_at">{{ $t('growing.view.plant_settings.transfer.label_enabled') }}</span>
                            <span class="text-danger" v-show="!stats.transfer_enabled_at">{{ $t('growing.view.plant_settings.transfer.label_disabled') }}</span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<style>

    .pointer {
        cursor: pointer;
    }

    .card-image .card-body div {
        overflow: hidden;
    }

    .badge-yellow {
        color: #292929;
        background-color: #ffdf32;
    }
</style>
<script>
    import MarkdownParser from "../../../../components/Common/MarkdownParser";
    import swal from "sweetalert2";

    export default {
        components: {
            MarkdownParser,
        },
        data() {
            return {
                stats: {
                    plants_active: 0,
                    plants: {
                        level_1: 0,
                        level_2: 0,
                        level_3: 0,
                    },
                    transfer_enabled_at: null,
                    transfer_requirements: {
                        verification_level: 0
                    }
                },
            }
        },
        mounted() {
            this.$api.get('growing/plants/overview').then(response => {
                this.stats = response.data;
            });
        },
        methods: {
            acceptTransferTerms() {
                this.stats.transfer_enabled_at = true;
                this.$api.post('growing/plants/settings/accept-transfer-terms').then(() => {

                }).catch((error) => {
                    swal.fire('Something went wrong', error.data.message, 'error');
                    this.stats.transfer_enabled_at = false;
                    return error;
                });
            },

        }
    }
</script>
